<template>
    <div class="vue-template auth">
        <form @submit.prevent="userRegistration">
            <h3>Sign Up</h3>
            <div class="d-grid gap-3">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control form-control-lg" v-model="user.name" />
                </div>

                <div>
                    <label>Select Event</label>
                    <select v-model=currentDbId id="dbName" class="form-select form-control form-control-sm">
                        <option value=0>Please select event to view</option>
                        <option v-for="name in collectionNames" :key="name.Id" :value="name.Id">
                            {{name.Name}}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control form-control-lg" v-model="user.email" />
                </div>

                <div class="form-group">
                    <label>Password</label>
                    <input type="password" class="form-control form-control-lg" v-model="user.password" />
                </div>

                <button type="submit" class="btn btn-dark btn-lg btn-block">Sign Up</button>

                <p class="forgot-password text-right">
                    Already registered 
                    <router-link :to="{name: 'login'}">sign in?</router-link>
                </p>
            </div>
        </form>
    </div>
</template>


<script>
import { auth } from '@/services/firebase/index.js';

export default {
    data() {
        return {
            currentDbId: 0,
            user: {
                name: '',
                email: '',
                password: ''
            }
        };
    },
    methods: {
        userRegistration() {
            auth.createUserWithEmailAndPassword(this.user.email, this.user.password)
            .then((res) => {
                res.user
                .updateProfile({
                    displayName: this.user.name
                })
                .then(() => {
                    var currentUser = auth.currentUser;
                    this.writeUserData(currentUser.uid, this.currentDbId, currentUser.name) ;
                    this.sendVerificationMail(currentUser);
                }).catch((error) => {
                    window.alert(error.message)
                });
            })
            .catch((error) => {
                alert(error.message);
            });
        },
        writeUserData(userId, collectionId, userName) {
            const info = {
                userId,
                collectionId,
                userName
            };
            this.$store.dispatch('writeUserInfo', info)
        },
        sendVerificationMail(currentUser) {
            return currentUser.sendEmailVerification()
            .then(() => {
                alert('User successfully registered!  Please validate your email address. Kindly check your inbox.')
                this.user = {
                    name: '',
                    email: '',
                    password: ''
                };
                this.$router.push('/');
            })
        }
    },
    computed: {
        collectionNames() {
            return this.$store.getters.getCollectionNames;
        }
    }
};
</script>
